<script>
import { AddressPurpose, BitcoinNetworkType, getAddress } from 'sats-connect';
import config, { envs } from '@/config';
import api from '@/components/btc-mint/api';
import { Order } from '@/components/btc-mint/order';

export default {
  name: 'ConnectWallet',
  props: {
    myTokenIds: {
      type: [Array, null],
      default: null,
    },
    connectingWallet: Boolean,
  },
  data: () => ({
    userOrdAddress: null,
    walletConnectedType: null,
    walletConnected: false,
    collectionId: envs.COLLECTION_ID,
    orderLoading: false,
    orders: [],
  }),
  mounted() {
    this.getUserWalletSession();
  },
  computed: {
    ordAddress() {
      return this.userOrdAddress.slice(0, 6) + '...' + this.userOrdAddress.slice(this.userOrdAddress.length - 6, this.userOrdAddress.length);
    },
  },
  methods: {
    setUserWalletSession(ordinalAddress, type) {
      console.log('setUserWalletSession', ordinalAddress);
      if (!ordinalAddress) {
        return;
      }

      this.userOrdAddress = ordinalAddress;

      localStorage.setItem('_btc_ordinal_address', ordinalAddress);
      localStorage.setItem('_btc_connected', '1');

      if (type) {
        localStorage.setItem('_btc_connected_type', type);
        this.walletConnectedType = type;
      }
      this.walletConnected = true;
      this.$emit('closeConnection');

      this.getOrders(this.userOrdAddress, false);
    },
    getUserWalletSession() {
      if (localStorage.getItem('_btc_ordinal_address')) {
        this.userOrdAddress = localStorage.getItem('_btc_ordinal_address');
      }

      if (localStorage.getItem('_btc_connected') === '1') {
        this.walletConnected = true;
      }

      if (localStorage.getItem('_btc_connected_type')) {
        this.walletConnectedType = localStorage.getItem('_btc_connected_type');
      }

      if (this.walletConnectedType === 'phantom') {
        this.connectUserWalletSessionPhantom();
      }
      if (this.walletConnectedType === 'unisat') {
        this.connectUserWalletSessionUnisat();
      }
    },
    async connectUserWalletSessionUnisat() {
      let unisatProvider = null;
      if ('unisat' in window) {
        const anyWindow = window;
        const provider = anyWindow.unisat;

        if (provider) {
          unisatProvider = provider;
        }
      }
      if (!unisatProvider) {
        this.showNotif({ type: 'error', text: this.$t('mint.notifs.connectError.unisat') });
        return;
      }

      const $this = this;
      const accounts = await unisatProvider.requestAccounts();
      // console.log(accounts);
      // unisatProvider.on('accountsChanged', (accounts) => {
      //   if (accounts.length > 0) {
      //     // Set new address and continue as usual
      //     setWallets(accounts);
      //   } else {
      //     // Attempt to reconnect to Phantom
      //     this.disconnectUserWalletSession();
      //   }
      // });

      const setWallets = (addresses) => {
        const ordinalAddress = addresses[0];

        this.setUserWalletSession(ordinalAddress, 'unisat');

        this.showNotif({ type: 'success', text: $this.$t('mint.notifs.connectSuccess.unisat') });
      };

      setWallets(accounts);
    },
    async connectUserWalletSessionXVerse() {
      const getAddressOptions = {
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: this.$t('mint.connectMessage'),
          network: {
            type: config.envs.NETWORK_NAME === 'testnet3' ? BitcoinNetworkType.Testnet : BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (result) => {
          if (result.addresses && result.addresses.length > 0) {
            const ordinalAddress = result.addresses.find((address) => address.purpose === AddressPurpose.Ordinals)?.address;

            this.setUserWalletSession(ordinalAddress, 'xverse');

            this.showNotif({ type: 'success', text: this.$t('mint.notifs.connectSuccess.xverse') });
          }
        },
        onCancel: () => {},
      };

      await getAddress(getAddressOptions);
    },
    async connectUserWalletSessionPhantom() {
      let phantomProvider = null;
      if ('phantom' in window) {
        const anyWindow = window;
        const provider = anyWindow.phantom?.bitcoin;

        if (provider && provider.isPhantom) {
          phantomProvider = provider;
        }
      }
      if (!phantomProvider) {
        this.showNotif({ type: 'error', text: this.$t('mint.notifs.connectError.phantom') });
        return;
      }

      const $this = this;
      const accounts = await phantomProvider.requestAccounts();
      phantomProvider.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          // Set new address and continue as usual
          setWallets(accounts);
        } else {
          // Attempt to reconnect to Phantom
          this.disconnectUserWalletSession();
        }
      });

      const setWallets = (addresses) => {
        const ordinalAddress = addresses.find((address) => address.purpose === AddressPurpose.Ordinals)?.address;

        this.setUserWalletSession(ordinalAddress, 'phantom');

        this.showNotif({ type: 'success', text: $this.$t('mint.notifs.connectSuccess.phantom') });
      };

      setWallets(accounts);
    },
    async connectUserWalletSessionMagicEden() {
      const getAddressOptions = {
        getProvider: async () => window.BitcoinProvider,
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: this.$t('mint.connectMessage'),
          network: {
            type: config.envs.NETWORK_NAME === 'testnet3' ? BitcoinNetworkType.Testnet : BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (result) => {
          if (result.addresses && result.addresses.length > 0) {
            const ordinalAddress = result.addresses.find((address) => address.purpose === AddressPurpose.Ordinals)?.address;

            this.setUserWalletSession(ordinalAddress, 'magicEden');

            this.showNotif({ type: 'success', text: this.$t('mint.notifs.connectSuccess.magicEden') });
          }
        },
        onCancel: () => {},
      };

      await getAddress(getAddressOptions);
    },
    async connectUserWalletSessionHiro() {
      // gestion via Hiro Wallet
      if (window.btc) {
        const userAddresses = await window.btc?.request('getAddresses');

        const ordinalAddress = userAddresses?.result?.addresses?.find((address) => address?.type === 'p2tr')?.address;

        this.setUserWalletSession(ordinalAddress, 'hiro');

        this.showNotif({ type: 'success', text: this.$t('mint.notifs.connectSuccess.hiro') });
      }
    },
    disconnectUserWalletSession() {
      if (!this.walletConnected) {
        return;
      }

      this.walletConnected = false;

      localStorage.removeItem('_btc_ordinal_address');
      localStorage.removeItem('_btc_cardinal_address');
      localStorage.removeItem('_btc_connected');
      localStorage.removeItem('_btc_connected_type');

      this.orders = [];
      return this.$emit('fetch', null);
    },
    async onConnectionOpen() {
      if (this.walletConnected) {
        if (this.myTokenIds !== null) {
          return this.$emit('fetch', null);
        }
        await this.getOrders(this.userOrdAddress);
        return;
      }
      if (this.connectingWallet) {
        this.$emit('closeConnection');
      } else {
        this.$emit('openConnection');
      }
    },
    showNotif() {},
    async getOrders(userOrdAddress, loader = true) {
      if (!userOrdAddress) {
        return;
      }

      api.init(envs.BACKEND_URI);
      this.orderLoading = true;

      const result = await api.get('https://api.miinded.com/v1/ordinal/6a11ca99-4a02-4f90-b8fc-e29976186294/tokens/' + userOrdAddress);
      if (result.error()) {
        this.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.orders') });
        this.orderLoading = false;
        return false;
      }

      this.orders = result.data().map((order) => new Order(order));
      const tokenIds = this.orders
        .map((order) =>
          order.nftsInscriptions.map((nft) => ({
            tokenId: nft.itemId,
            id: nft.id,
          })),
        )
        .flat();
      tokenIds.sort();

      this.$emit('fetch', tokenIds);
      this.$emit('closeFilter');
      this.orderLoading = false;
      return true;
    },
  },
};
</script>

<template>
  <div class="wallet-connect">
    <div class="btn my-ailu" @click.stop="onConnectionOpen" :class="{ active: myTokenIds !== null || connectingWallet }">
      <div class="text">
        <div class="checkbox" :class="{ active: myTokenIds !== null }">
          <svg class="ico" v-if="myTokenIds !== null"><use xlink:href="#ico-cross"></use></svg>
        </div>
        My AILUs
      </div>
      <div class="sub-text" v-if="!walletConnected">
        <svg class="ico"><use xlink:href="#ico-wallet"></use></svg>
        Not Connected
      </div>
      <div class="sub-text" v-if="orderLoading">Loading...</div>
      <div class="sub-text" v-else-if="walletConnected">
        <a @click.prevent.stop="disconnectUserWalletSession"> Disconnect </a>
      </div>
    </div>
    <div class="wallet-connect-popup" @click.prevent.stop="close" ref="btc-mint__connect" :class="{ open: connectingWallet }">
      <div class="btc-mint__container-wrapper" ref="btc-mint__container-wrapper">
        <div class="sub-title">
          <span>{{ $t('connect.connect') }}</span>
        </div>
        <div class="option" @click="connectUserWalletSessionXVerse">
          <div>{{ $t('connect.xverse') }}</div>
        </div>
        <div class="option" @click="connectUserWalletSessionUnisat">
          <div>{{ $t('connect.unisat') }}</div>
        </div>
        <div class="option" @click="connectUserWalletSessionHiro">
          <div>{{ $t('connect.hiro') }}</div>
        </div>
        <div class="option" @click="connectUserWalletSessionPhantom">
          <div>{{ $t('connect.phantom') }}</div>
        </div>
        <div class="option" @click="connectUserWalletSessionMagicEden">
          <div>{{ $t('connect.magicEden') }}</div>
        </div>
        <!--      <div class="btc-mint__row &#45;&#45;center &#45;&#45;mb-s" @click="close(null)">-->
        <!--        <div class="btc-mint__link btc-mint__c-primary-desaturated">{{ $t('connect.noconnect') }}</div>-->
        <!--      </div>-->
      </div>
      <!--      <div class="btc-mint__row &#45;&#45;center &#45;&#45;mb-s" @click="close(null)">-->
      <!--        <div class="btc-mint__link btc-mint__c-primary-desaturated">{{ $t('connect.noconnect') }}</div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<style scoped lang="scss">
.wallet-connect {
  position: relative;
  z-index: 10;

  .my-ailu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 8px 16px;
    border: 1px solid #356666;
    @media (max-width: 1200px) {
      padding: 12px 16px;
    }
    &.active {
      border: 1px solid #00ffff;
    }
    .ico {
      margin: 0;
    }
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    .sub-text {
      font-size: 12px;
      text-transform: none;
      color: #39a0a0;
      display: flex;
      align-items: center;
      gap: 5px;
      a {
        color: #39a0a0;
      }
    }
  }
  .wallet-connect-popup {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 10px);
    background: #004444;
    border: 1px solid #356666;
    padding: 0px 0;
    border-radius: 4px;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
    max-height: 30vh;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    overflow: auto;

    &.open {
      opacity: 1;
      pointer-events: auto;
      height: auto;
    }

    .sub-title {
      color: #00ffff;
      text-transform: uppercase;
      font-family: 'Itc Bold', serif;
      padding: 10px 20px;
      border-bottom: 1px solid #356666;
    }

    .option {
      position: relative;
      padding: 15px 20px;
      cursor: pointer;
      transition: 0.3s;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        border-bottom: 1px solid #356666;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .checkbox {
    width: 20px;
    height: 20px;
    background: #071010;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    svg {
      width: 10px;
      height: 10px;
      fill: #00ffff;
    }
  }
}
</style>
